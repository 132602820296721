import { z } from 'zod'

const stringError = (msg: string) => {
  return {
    required_error: 'Toto pole je povinné',
    invalid_type_error: 'Vyplňte ' + msg,
  }
}

const email = z.object({
  email: z
    .string(stringError('svůj email'))
    .email({ message: 'Email není ve správném formátu' })
    .or(z.literal('')),
})

const phone = z.object({
  phone: z.string(stringError('svoje telefonní číslo')),
})

const form = z
  .object({
    firstName: z
      .string(stringError('své jméno'))
      .nonempty('Toto pole nesmí být prázdné'),
    lastName: z
      .string(stringError('své příjmení'))
      .nonempty('Toto pole nesmí být prázdné'),
    firmName: z.string(stringError('své příjmení')).optional(),
    message: z
      .string(stringError('zprávu'))
      .nonempty('Toto pole nesmí být prázdné'),
  })
  .merge(email.partial())
  .merge(phone.partial())
  .superRefine((data, ctx) => {
    if (data.email && data.email.length) {
      const result = email.safeParse(data)
      if (!result.success) {
        result.error.errors.forEach((issue) => ctx.addIssue(issue))
      }
    } else if (data.phone) {
      const result = phone.safeParse(data)
      if (!result.success) {
        result.error.errors.forEach((issue) => ctx.addIssue(issue))
      }
    } else {
      ctx.addIssue({
        code: 'custom',
        path: ['email'],
        message: 'Musíte vyplnit email nebo telefonní číslo',
      })
      ctx.addIssue({
        code: 'custom',
        path: ['phone'],
        message: 'Musíte vyplnit email nebo telefonní číslo',
      })
    }
  })

type Form = z.infer<typeof form>

export { form, Form }
