<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import StateEmitter from '~/utils/StateEmitter'

const props = defineProps<{
  items: { label: string; content: string }[]
}>()

const buttonRefs = ref<Function[]>([])

function closeOthers(itemIndex: number) {
  buttonRefs.value.forEach((close, index) => {
    if (index === itemIndex) return

    close()
  })
}

function onEnter(el: HTMLElement, done: () => void) {
  el.style.height = '0'
  el.offsetHeight // Trigger a reflow, flushing the CSS changes
  el.style.height = el.scrollHeight + 'px'

  el.addEventListener('transitionend', done, { once: true })
}

function onBeforeLeave(el: HTMLElement) {
  el.style.height = el.scrollHeight + 'px'
  el.offsetHeight // Trigger a reflow, flushing the CSS changes
}

function onAfterEnter(el: HTMLElement) {
  el.style.height = 'auto'
}

function onLeave(el: HTMLElement, done: () => void) {
  el.style.height = '0'

  el.addEventListener('transitionend', done, { once: true })
}
</script>

<template>
  <Disclosure
    v-for="(item, index) in props.items"
    v-slot="{ open, close }"
    as="div"
    class="block rounded-2xl text-white transition-all"
  >
    <DisclosureButton
      :ref="() => (buttonRefs[index] = close)"
      class="flex w-full items-center justify-between rounded-lg bg-white/10 px-4 py-2 text-left font-medium text-white hover:bg-white/20 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
    >
      <span class="text-lg">{{ item.label }}</span>
      <div class="w-5">
      <Icon
        name="fa6-solid:chevron-down"
        :class="open ? 'rotate-180 transform' : ''"
        class="w-5 h-5 transition-transform duration-200"
      />
      </div>
    </DisclosureButton>

    <StateEmitter :open="open" @open="closeOthers(index)" />

    <Transition
      enter-active-class="overflow-hidden transition-[height] duration-200 ease-out"
      leave-active-class="overflow-hidden transition-[height] duration-200 ease-out"
      @enter="onEnter"
      @after-enter="onAfterEnter"
      @before-leave="onBeforeLeave"
      @leave="onLeave"
    >
      <div v-if="open">
        <DisclosurePanel class="min-h-full px-4 py-4">
          {{ item.content }}
        </DisclosurePanel>
      </div>
    </Transition>
  </Disclosure>
</template>
