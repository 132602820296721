<script setup lang="ts">
import { form } from '~/types/form'
import type { Form } from '~/types/form'

const validationSchema = toTypedSchema(form)
const showError = ref(false)
const errorMessage =
  'Něco se pokazilo, zkuste formulář odeslat za chvíli znovu.'

async function submit(values: Form) {
  loading.value = true
  showError.value = false

  const res = await useFetch('/api/send-email', {
    method: 'POST',
    body: {
      ...values,
    },
  })

  if (res.error.value) {
    showError.value = true
  }

  if (res.data.value?.ok) {
    emailSent.value = true
  }

  loading.value = false
}

const loading = ref(false)
const emailSent = ref(false)
</script>

<template>
  <section
    id="kontakt"
    class="relative overflow-hidden bg-neutral-900 px-4 py-16 text-white"
  >
    <div
      class="absolute -left-20 -top-20 z-[1] h-96 w-96 rounded-full bg-optilynx-pink blur-3xl"
    ></div>
    <div
      class="absolute left-20 top-20 z-0 h-96 w-96 rounded-full bg-optilynx-purple blur-3xl"
    ></div>
    <div class="container relative z-10 mx-auto">
      <div class="grid items-center gap-5 md:grid-cols-2">
        <div class="text-left">
          <h3 class="mb-8 text-5xl font-medium">{{ $t('contacts.title') }}</h3>
          <p class="mb-6 text-xl">{{ $t('contacts.subtitle') }}</p>
          <div class="flex w-fit flex-col items-start gap-2 text-xl">
            <NuxtLink to="mailto:info@optilynx.cz">
              <Icon class="mr-2 inline-block" name="fa6-solid:envelope" />
              info@optilynx.cz
            </NuxtLink>
            <NuxtLink to="tel:+420737263626">
              <Icon class="mr-2 inline-block" name="fa6-solid:phone" />
              +420 737 26 36 26
            </NuxtLink>
            <NuxtLink to="tel:+420603140747">
              <Icon class="mr-2 inline-block" name="fa6-solid:phone" />
              +420 603 140 747
            </NuxtLink>
          </div>
        </div>
        <div class="min-h-[500px] max-w-xl">
          <Form
            v-if="!emailSent"
            ref="formRef"
            @submit="submit"
            v-slot="{ meta }"
            class="my-8 grid grid-cols-2 gap-4"
            :validation-schema="validationSchema"
          >
            <div class="col-span-2 flex w-full flex-col sm:col-span-1">
              <label for="firstName" class="mb-2">{{ $t('contacts.form.name') }}</label>
              <Field
                id="firstName"
                name="firstName"
                class="mb-2 w-full rounded-md bg-neutral-800 px-3 py-1 ring-1 ring-neutral-600 focus:outline-none focus:ring-2 focus:ring-neutral-300"
              />
              <ErrorMessage name="firstName" class="text-sm text-red-600" />
            </div>
            <div class="col-span-2 flex w-full flex-col sm:col-span-1">
              <label for="lastName" class="mb-2">{{ $t('contacts.form.lastName') }}</label>
              <Field
                id="lastName"
                name="lastName"
                class="mb-2 w-full rounded-md bg-neutral-800 px-3 py-1 ring-1 ring-neutral-600 focus:outline-none focus:ring-2 focus:ring-neutral-300"
              />
              <ErrorMessage name="lastName" class="text-sm text-red-600" />
            </div>

            <div class="col-span-2 flex w-full flex-col">
              <label for="firmName" class="mb-2">{{ $t('contacts.form.company') }}</label>
              <Field
                id="firmName"
                name="firmName"
                class="mb-2 w-full rounded-md bg-neutral-800 px-3 py-1 ring-1 ring-neutral-600 focus:outline-none focus:ring-2 focus:ring-neutral-300"
              />
              <ErrorMessage name="firmName" class="text-sm text-red-600" />
            </div>

            <div class="col-span-2 flex w-full flex-col">
              <label for="email" class="mb-2">{{ $t('contacts.form.email') }}</label>
              <Field
                id="email"
                name="email"
                class="mb-2 w-full rounded-md bg-neutral-800 px-3 py-1 ring-1 ring-neutral-600 focus:outline-none focus:ring-2 focus:ring-neutral-300"
              />
              <ErrorMessage name="email" class="text-sm text-red-600" />
            </div>
            <div class="col-span-2 flex w-full flex-col">
              <label for="phone" class="mb-2">{{ $t('contacts.form.phone') }}</label>
              <Field
                id="phone"
                name="phone"
                class="mb-2 w-full rounded-md bg-neutral-800 px-3 py-1 ring-1 ring-neutral-600 focus:outline-none focus:ring-2 focus:ring-neutral-300"
              />
              <ErrorMessage name="phone" class="text-sm text-red-600" />
            </div>
            <div class="col-span-2 flex w-full flex-col">
              <label for="message" class="mb-2">{{ $t('contacts.form.message') }}</label>
              <Field name="message" v-slot="{ field }">
                <textarea
                  id="message"
                  v-bind="field"
                  class="mb-2 w-full rounded-md bg-neutral-800 px-3 py-1 ring-1 ring-neutral-600 focus:outline-none focus:ring-2 focus:ring-neutral-300"
                  rows="6"
                />
              </Field>
              <ErrorMessage name="message" class="text-sm text-red-600" />
            </div>
            <UiButton
              :disabled="!meta.valid || loading"
              class="col-span-2 w-full"
            >
              <span v-if="!loading">{{ $t('contacts.form.submit') }}</span>
              <span class="flex items-center justify-center gap-1" v-else>
                {{ $t('contacts.form.sending') }}
                <Icon name="line-md:loading-twotone-loop" size="24" />
              </span>
            </UiButton>
            <div
              v-if="showError"
              class="col-span-2 w-full rounded-md border border-red-500 bg-red-100 p-4 text-red-500"
            >
              {{ errorMessage }}
            </div>
          </Form>

          <div v-else class="grid h-[500px] place-content-center">
            <div
              class="w-full rounded-2xl bg-optilynx-pink p-20 text-center text-lg"
            >
              <p>{{ $t('contacts.form.sent') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
