<script setup lang="ts">
const localePath = useLocalePath()
</script>

<template>
  <section class="px-4 py-16 xl:pb-60">
    <div class="container relative mx-auto h-full max-w-7xl text-center">
      <div class="left-2 top-40 mx-auto mb-8 w-fit xl:absolute xl:mb-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="384.005"
          height="401.45"
          viewBox="0 0 384.005 401.45"
          class="h-fit w-52 max-w-full xl:w-full"
        >
          <g id="woman" transform="translate(-212.663 -203.284)">
            <path
              id="Path_33"
              data-name="Path 33"
              d="M792,565.367a192.184,192.184,0,0,1-105.99,171.7q-2.445,1.23-4.94,2.39a192.754,192.754,0,0,1-137.16,9.59q-6.675-2.04-13.14-4.54A191.99,191.99,0,0,1,600,373.367C706.037,373.367,792,459.327,792,565.367Z"
              transform="translate(-195.329 -152.633)"
              fill="#a7005e"
              opacity="0.2"
            />
            <circle
              id="Ellipse_20"
              data-name="Ellipse 20"
              cx="23.33"
              cy="23.33"
              r="23.33"
              transform="translate(350.791 247.629)"
              fill="#2f2e41"
            />
            <circle
              id="Ellipse_21"
              data-name="Ellipse 21"
              cx="59.104"
              cy="59.104"
              r="59.104"
              transform="translate(352.345 264.738)"
              fill="#2f2e41"
            />
            <circle
              id="Ellipse_22"
              data-name="Ellipse 22"
              cx="48.028"
              cy="48.028"
              r="48.028"
              transform="translate(368.312 286.874)"
              fill="#9e616a"
            />
            <path
              id="Path_65"
              data-name="Path 65"
              d="M644.285,428.577a55.015,55.015,0,0,0-83.642,48.014c24.473,6.144,50.183,10.847,76.282,1.47l6.163-15.084,3.634,15.1c7.948,3.249,15.916,6,23.885-.031A56.174,56.174,0,0,0,644.285,428.577Z"
              transform="translate(-195.329 -142.633)"
              fill="#2f2e41"
            />
            <path
              id="Path_66"
              data-name="Path 66"
              d="M520.191,566.367h-.063a15.66,15.66,0,0,1-14.71-9.889,16,16,0,0,1,3.652-17.733l.27-.268A49.1,49.1,0,0,0,523.6,504.056c.011-18.219-10.187-35.057-19.748-49.409l-1.762-2.64c-9.528-14.253-19.38-28.991-21.888-45.969-2.663-18.031,4.165-37.062,17.394-48.486,13.732-11.856,34.433-15.043,50.343-7.749,16.49,7.561,27.1,26.4,24.674,43.814l-.025.181-5.795,5.213-.213-.016c-13.722-1.058-27.39,7.983-31.806,21.011-2.974,8.77-1.9,18.379-.307,27.457A242.679,242.679,0,0,0,551.427,503.3q.6,1.361,1.215,2.732c4.593,10.284,9.342,20.918,7.523,31.933-2.875,17.407-21.351,27.594-37.276,28.34Q521.537,566.368,520.191,566.367Z"
              transform="translate(-195.329 -142.633)"
              fill="#2f2e41"
            />
            <path
              id="Path_67"
              data-name="Path 67"
              d="M729.127,707.467a192.588,192.588,0,0,1-43.12,29.6l-3.31-10.66-2.67,10.39,1.04,2.66c-1.15.54-2.31,1.06-3.47,1.57q-1.485.66-2.97,1.29a192.837,192.837,0,0,1-114.76,10.84c-1.01-.21-2.01-.43-3.01-.66-4.37-1-8.69-2.16-12.95-3.45l5.96-22.16-5.3-26.79-13.8,44.41a191.757,191.757,0,0,1-44.02-24.08c4.24-27.41,9.21-60.31,12.28-83.08,1.11-8.32,1.97-15.28,2.45-20.14a61.935,61.935,0,0,1,2.66-13.41,36.553,36.553,0,0,1,2.59-6.1c.22-.43.46-.84.7-1.24s.47-.76.72-1.12c6.14-9.15,14.02-8.59,14.02-8.59l23.52-4.59,7.35-1.44,2.99-.58,3.66-.72a20.377,20.377,0,0,0,13.55-9.48l9.99-16.59a20.453,20.453,0,0,1,8.96-8.01c.45-.2.91-.39,1.37-.57a20.2,20.2,0,0,1,6.34-1.27l14.14-.55,5.72-.23,7.81-.3a20.011,20.011,0,0,1,5.48.54h.01c.63.15,1.23.32,1.84.53a20.355,20.355,0,0,1,11.17,9.2l6.98,12.24a20.387,20.387,0,0,0,15.19,10.13l2.68.33,3.01.38,7.61.95,13.9,1.74.03.53c2.66.21,10.24,1.77,15.32,12.6.34.71.66,1.47.97,2.26a57.825,57.825,0,0,1,3.37,14.92c.03.22.05.44.07.66C716.9,626.757,723.487,670.947,729.127,707.467Z"
              transform="translate(-195.329 -152.633)"
              fill="#2f2e41"
            />
            <path
              id="Path_68"
              data-name="Path 68"
              d="M615.876,752.787l-.949,4.43-2.93-.63.871-4.063a168.289,168.289,0,0,0,3.7-39.028l-3.682-165.739-.07-2.98-.04-1.81,1.52-.03,1.48-.03.15,6.88,3.642,164.007A168.289,168.289,0,0,1,615.876,752.787Z"
              transform="translate(-195.329 -152.633)"
              fill="#a7005e"
            />
            <path
              id="Path_69"
              data-name="Path 69"
              d="M303.018,499.674l42.18,5.76,2.95.4,1.8.25-.21,1.5-.21,1.47-6.82-.93-40.1-5.48Z"
              transform="translate(0 -10)"
              fill="#a7005e"
            />
            <path
              id="Path_70"
              data-name="Path 70"
              d="M624.227,545.707l-4.47-2.99-5.46.22-.26.01-1.22,1.83.07,2.98,3.04,2.03,3.85,2.57a4,4,0,0,0,4.45-6.65Zm-.56,4.44a2,2,0,1,1-.55-2.78A2,2,0,0,1,623.667,550.147Z"
              transform="translate(-195.329 -152.633)"
              fill="#a7005e"
            />
            <g
              id="Group_2"
              data-name="Group 2"
              transform="translate(-912.825 58.312)"
            >
              <g
                id="Ellipse_23"
                data-name="Ellipse 23"
                transform="translate(1283 268)"
                fill="none"
                stroke="#a7005e"
                stroke-width="5"
              >
                <circle cx="19" cy="19" r="19" stroke="none" />
                <circle cx="19" cy="19" r="16.5" fill="none" />
              </g>
              <g
                id="Ellipse_24"
                data-name="Ellipse 24"
                transform="translate(1338 268)"
                fill="none"
                stroke="#a7005e"
                stroke-width="5"
              >
                <circle cx="19" cy="19" r="19" stroke="none" />
                <circle cx="19" cy="19" r="16.5" fill="none" />
              </g>
              <path
                id="Path_71"
                data-name="Path 71"
                d="M-2.833,0H15.088"
                transform="translate(1323.59 287.249)"
                fill="none"
                stroke="#a7005e"
                stroke-width="5"
              />
            </g>
          </g>
        </svg>
      </div>
      <div class="xl:pt-14">
        <h1
          class="z-10 mx-auto mb-4 max-w-5xl text-4xl font-semibold xl:mb-8 xl:text-8xl"
        >
          <!-- Zaostřeno na lepší výsledky -->
          {{ $t('hero.title') }}
        </h1>
        <p class="text-md mb-6 xl:text-2xl">
          {{ $t('hero.subtitle') }}
        </p>
        <div class="flex flex-col sm:flex-row justify-center gap-4 mx-auto">
          <NuxtLink class="w-full sm:w-auto" :to="{ path: localePath('/'), hash: '#kontakt' }">
            <UiButton class="w-full sm:w-auto">{{ $t('hero.tryOptilynx') }}</UiButton>
          </NuxtLink>
          <NuxtLink class="w-full sm:w-auto" :to="{ path: localePath('/'), hash: '#funkce' }">
            <UiButton class="w-full sm:w-auto" dark>{{ $t('hero.about') }}</UiButton>
          </NuxtLink>
        </div>
      </div>
    </div>
  </section>
</template>
