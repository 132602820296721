<script setup lang="ts">

const { t } = useI18n()

const setupItems = [
  {
    label: t('faq.setup.first.title'),
    content: t('faq.setup.first.text')
  },
  {
    label: t('faq.setup.second.title'),
    content: t('faq.setup.second.text')

  },
  {
    label: t('faq.setup.third.title'),
    content: t('faq.setup.third.text')},
  {
    label: t('faq.setup.fourth.title'),
    content: t('faq.setup.fourth.text')
  },
  {
    label: t('faq.setup.fifth.title'),
    content: t('faq.setup.fifth.text')
  },
]

const usageItems = [
  {
    label: t('faq.usage.first.title'),
    content: t('faq.usage.first.text')
  },
  {
    label: t('faq.usage.second.title'),
    content: t('faq.usage.second.text')
  },
  {
    label: t('faq.usage.third.title'),
    content: t('faq.usage.third.text')
  },
  {
    label: t('faq.usage.fourth.title'),
    content: t('faq.usage.fourth.text')
  },
  {
    label: t('faq.usage.fifth.title'),
    content: t('faq.usage.fifth.text')
  },
  {
    label: t('faq.usage.sixth.title'),
    content: t('faq.usage.sixth.text')
  },
  // {
  //   label: '',
  //   content: ``,
  // },
]

const items = [
  {
    label: t('faq.setup.title'),
    qnas: setupItems,
  },
  {
    label: t('faq.usage.title'),
    qnas: usageItems,
  },
]
</script>

<template>
  <UiSection id="faq" :title="$t('faq.title')">
    <div v-for="item in items" class="mb-4 block rounded-2xl transition-all">
      <div class="block rounded-2xl bg-optilynx-purple transition-all">
        <h3 class="w-full pt-4 text-center text-2xl font-semibold text-white">
          {{ item.label }}
        </h3>
        <div class="flex flex-col gap-2 p-4 transition-all">
          <UiAccordion :items="item.qnas" />
        </div>
      </div>
    </div>
    <div class="pt-3 text-xl">
      <div class="w-max mx-auto">
      {{ $t('cooperation') }}
      </div>
      <a href="https://scoo.cz/" class="w-max" target="_blank">
        <img class="w-80 cursor-pointer pt-2 filter mx-auto grayscale brightness-110 hover:filter-none transition-all duration-100"
             src="assets/png/scooLogo.png"
        >
      </a>
    </div>
  </UiSection>
</template>
