<script setup lang="ts">
const { t } = useI18n()

useSeoMeta({
  title: t('pages.index.meta.title'),
  description: t('pages.index.meta.description'),
})

defineOgImage({
  component: 'Default',
})
</script>

<template>
  <main>
    <HomepageHero />
    <HomepageAbout />
    <HomepageFaq />
    <HomepagePricing />
    <HomepageContact />
  </main>
</template>
