<script setup lang="ts">
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupDescription,
  RadioGroupOption,
} from '@headlessui/vue'

const localePath = useLocalePath()

const { t } = useI18n()

enum PaymentType {
  MONTHLY,
  YEARLY,
}

const paymentType = [
  {
    name: t('pricing.monthly.title'),
    id: PaymentType.MONTHLY,
  },
  {
    name: t('pricing.oneTime.title'),
    id: PaymentType.YEARLY,
  },
]

const plans = [
  {
    name: t('pricing.firstBranch'),
    price: {
      monthly: {
        installation: t('pricing.free'),
        subscription: '1 549 Kč',
      },
      yearly: {
        installation: '25 900 Kč',
        subscription: '200 Kč',
      },
    },
  },
  {
    name: t('pricing.secondBranch'),
    price: {
      monthly: {
        installation: t('pricing.free'),
        subscription: '1 199 Kč',
      },
      yearly: {
        installation: '18 900 Kč',
        subscription: '200 Kč',
      },
    },
  },
  {
    name: t('pricing.thirdBranch'),
    price: {
      monthly: {
        installation: t('pricing.free'),
        subscription: '1 099 Kč',
      },
      yearly: {
        installation: '17 900 Kč',
        subscription: '200 Kč',
      },
    },
  },
  {
    name: t('pricing.fourthBranch'),
    price: {
      monthly: {
        installation: t('pricing.free'),
        subscription: '999 Kč',
      },
      yearly: {
        installation: '16 900 Kč',
        subscription: '200 Kč',
      },
    },
  },
  {
    name: t('pricing.fifthAndMore'),
    price: {
      monthly: {
        installation: t('pricing.free'),
        subscription: t('pricing.individually'),
      },
      yearly: {
        installation: t('pricing.individually'),
        subscription: '200 Kč',
      },
    },
  },
]

const selected = ref(paymentType[0])
</script>

<template>
  <UiSection id="cenik" :title="$t('pricing.title')">
    <div>
      <h2
        v-if="selected.id === PaymentType.MONTHLY"
        class="mb-8 text-center text-3xl lg:text-5xl font-semibold"
      >
        {{ $t('pricing.monthly.subtitle') }}
      </h2>
      <h2
        v-else-if="selected.id === PaymentType.YEARLY"
        class="mb-8 text-center text-3xl lg:text-5xl font-semibold"
      >
        {{ $t('pricing.oneTime.subtitle') }}
      </h2>
      <p
        v-if="selected.id === PaymentType.MONTHLY"
        class="mx-auto mb-16 max-w-2xl text-center text-lg"
      >
        {{ $t('pricing.monthly.text') }}
      </p>
      <p
        v-else-if="selected.id === PaymentType.YEARLY"
        class="mx-auto mb-16 max-w-2xl text-center text-lg"
      >
        {{ $t('pricing.oneTime.text') }}
      </p>

      <div class="sticky top-16 w-full px-4 pb-8 z-10">
        <div class="mx-auto w-full max-w-md">
          <RadioGroup v-model="selected">
            <RadioGroupLabel class="sr-only">Server size</RadioGroupLabel>
            <div
              class="mx-auto flex w-fit space-x-1 rounded-full bg-neutral-300 p-1"
            >
              <RadioGroupOption
                v-for="plan in paymentType"
                :key="plan.name"
                v-slot="{ active, checked }"
                as="template"
                :value="plan"
              >
                <div
                  :class="[
                    checked && plan.id === PaymentType.MONTHLY
                      ? '!bg-optilynx-pink text-white hover:!bg-optilynx-pink-hover '
                      : 'bg-white hover:bg-neutral-50',
                    checked && plan.id === PaymentType.YEARLY
                      ? '!bg-optilynx-purple text-white hover:!bg-optilynx-purple-hover '
                      : 'bg-white hover:bg-neutral-50',
                  ]"
                  class="relative flex cursor-pointer rounded-full px-5 py-2 focus:outline-none"
                >
                  <div class="flex w-full items-center justify-between">
                    <div class="flex items-center">
                      <div class="text-sm">
                        <RadioGroupLabel
                          as="p"
                          :class="checked ? 'text-white' : 'text-gray-900'"
                          class="font-medium"
                        >
                          {{ plan.name }}
                        </RadioGroupLabel>
                      </div>
                    </div>
                  </div>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>
      </div>

      <div
        class="mx-auto grid grid-cols-1 gap-5 text-black sm:grid-cols-2 md:grid-cols-3"
      >
        <div
          v-for="(plan, index) in plans"
          class="overflow-hidden rounded-2xl p-8 text-white relative"
          :class="{
            'sm:col-span-2': index === plans.length - 1,
            'bg-optilynx-pink': selected.id === PaymentType.MONTHLY,
            'bg-optilynx-purple': selected.id === PaymentType.YEARLY,
          }"
        >
          <span v-if="index < 4" class="absolute right-1 -bottom-6 opacity-50 text-[150px] leading-[1] italic" style="background-image: '';">{{ index + 1 }}</span>
          <span v-else class="absolute right-1 -bottom-6 opacity-50 text-[150px] leading-[1] italic" style="background-image: '';">{{ index + 1 }}+</span>
          <p class="mb-2 w-fit text-xl">{{ plan.name }}</p>
          <div v-if="selected.id === PaymentType.MONTHLY">
            <div class="mb-2">
              <p>{{ $t('pricing.fees.instalation') }}</p>
              <p class="text-2xl font-bold capitalize">
                {{ plan.price.monthly.installation }}
              </p>
            </div>
            <div>
              <p>{{ $t('pricing.fees.subscription') }}</p>
              <p class="text-2xl font-bold capitalize">
                {{ plan.price.monthly.subscription }}
              </p>
            </div>
          </div>
          <div v-if="selected.id === PaymentType.YEARLY">
            <div class="mb-2">
              <p>{{ $t('pricing.fees.instalation') }}</p>
              <p class="text-2xl font-bold capitalize">
                {{ plan.price.yearly.installation }}
              </p>
            </div>
            <div>
              <p>{{ $t('pricing.fees.operating') }}</p>
              <p class="text-2xl font-bold capitalize">
                {{ plan.price.yearly.subscription }}
                <span class="text-sm lowercase">{{ $t('pricing.monthly.unit') }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </UiSection>
</template>
