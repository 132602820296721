<script setup lang="ts">

</script>

<template>
  <UiSection id="funkce" :title="$t('about.title')">
    <div class="grid gap-4 lg:grid-cols-2">
      <div
          class="flex w-full flex-col items-center overflow-hidden rounded-2xl bg-optilynx-pink text-white md:flex-row lg:col-span-2 lg:py-12"
      >
        <div class="w-full p-10">
          <h3 class="mb-4 text-3xl font-medium">{{ $t('about.transactionTitle') }}</h3>
          <p class="text-lg">
            {{ $t('about.transactions') }}
          </p>
        </div>
        <div class="ml-auto w-2/3 sm:w-1/2 md:w-full lg:ml-0">
          <img class="sm:p-4" src="/img/svg/list.svg" alt=""/>
        </div>
      </div>
      <div class="relative gap-5 overflow-hidden rounded-2xl bg-white">
        <div class="w-full p-10">
          <h3 class="mb-4 text-3xl font-medium">{{ $t('about.customersTitle') }}</h3>
          <p class="text-lg">
            {{ $t('about.customersText') }}
          </p>
        </div>
      </div>
      <div class="relative gap-5 overflow-hidden rounded-2xl bg-white">
        <div class="w-full p-10">
          <h3 class="mb-4 text-3xl font-medium">{{ $t('about.ordersTitle') }}</h3>
          <p class="text-lg">
            {{ $t('about.ordersText') }}
          </p>
        </div>
      </div>
      <div
          class="relative flex w-full flex-col items-center overflow-hidden rounded-2xl bg-neutral-900 text-white md:flex-row lg:col-span-2 lg:py-12"
      >
        <div class="w-full p-10 pb-56 lg:pb-10">
          <h3 class="mb-4 text-3xl font-medium">{{ $t('about.warehouseTitle') }}</h3>
          <p class="text-lg md:w-2/3 lg:w-1/2">
            {{ $t('about.warehouseText') }}
          </p>
        </div>
        <div
            class="absolute -bottom-0.5 -right-16 ml-auto w-80 sm:w-1/2 lg:ml-0"
        >
          <img class="md:p-4" src="/img/svg/boxes.svg" alt=""/>
        </div>
      </div>
    </div>
    <div class="bg-optilynx-pink text-white text-center rounded-xl p-5 pt-10 w-full mt-4">
      <h3 class="text-3xl font-medium">{{ $t('about.demo.title') }}</h3>
      <div class="py-5 w-full">
        <div class="aspect-video overflow-hidden rounded-xl">
          <iframe width="1920" height="1080" class="max-w-full h-full" src="https://www.youtube.com/embed/Aqy-DAhLJS0?si=b-OJC0t8RKP2EjTS&rel=0&loop=1&controls=1&autoplay=1&playlist=Aqy-DAhLJS0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; loop;" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
    <UiGallery/>
    </div>
  </UiSection>
</template>
