<script setup lang="ts">

const { $simpleLightbox } = useNuxtApp()
const lightBoxInit = ref(false)

function onImageMounted() {
  // have to wait for mount of any child image to initialize slider
  if (!lightBoxInit.value) {
    lightBoxInit.value = true
    $simpleLightbox('.data-lineGallery a')
  }
}
const imagePaths = [
  {
    image: 'img/png/cashRegister.png',
    title: 'about.demo.cashRegister'
  },
  {
    image: 'img/png/orders.png',
    title: 'about.demo.orders'
  },
  {
    image: 'img/png/orderDetail.png',
    title: 'about.demo.orderDetail'
  }]

</script>

<template>
  <div class="container mx-auto flex justify-center">
    <div class="data-lineGallery grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 items-center gap-x-10 py-5">
      <div
          v-for="image in imagePaths"
          :key="image.image"
          class="gallery-item w-full"
      >
        <a
            class="gallery-carousel-slide relative inline-block after:absolute after:left-0 after:top-0 after:box-border after:block after:h-full after:w-full after:bg-[50%] after:bg-no-repeat after:opacity-0 after:transition-opacity after:content-['']"
            :href="image.image"
        >
          <img
              class="lazy-img gallery-image block h-full w-auto rounded-xl"
              :src="image.image"
              :alt="image.image"
              @vue:mounted="onImageMounted"
          />
        </a>
        <div>
          {{ $t(image.title) }}
        </div>
      </div>
      <div class="padding-item" />
    </div>
  </div>

</template>

<style scoped>

</style>